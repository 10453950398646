import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { chakra, Container } from "@chakra-ui/react";
import { BiBlock, BiCaretRight, BiCaretLeft } from "react-icons/bi";
import { FaSortNumericDown, FaSortAlphaDown } from "react-icons/fa";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { IoHourglassOutline } from "react-icons/io5";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Infos | THE PROJECT STATUS" urlPage="/about" mdxType="SEO" />
    <Container maxW="800px" minH="640px" px={4} mdxType="Container">
      <h1 {...{
        "id": "infos",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#infos",
          "aria-label": "infos permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Infos`}</h1>
      <p>{`Welcome to THE PROJECT STATUS. The purpose of this app is to support proper
project status reporting. Research suggests that transparency and discipline
that is achieved by help of status reporting will significantly contribute to
project success.`}</p>
      <br />
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "9.166666666666668%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAd0lEQVQI1x3HwQrCMAwA0P3/tzhEUKFqx9gPKHhXuiRNutaTa7oeJqKXB6/pbG/tYE520+7a7f56u5tzdziai+0R6Z2LI3EkT+QHeOAAPlBIHF88pUZYEH2KCQDJM/9K4wiquq6fWUtZav6rZVlqnbM6EpKIMn0BBQxpXFtpmqkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Don't fly blind",
            "title": "Don't fly blind",
            "src": "/static/b5da4b73e79f6c58b81189dd2c6b9402/78d47/project-status-org_dont-fly-blind.png",
            "srcSet": ["/static/b5da4b73e79f6c58b81189dd2c6b9402/9aebd/project-status-org_dont-fly-blind.png 480w", "/static/b5da4b73e79f6c58b81189dd2c6b9402/78d47/project-status-org_dont-fly-blind.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <br />
      <p>{`Running projects is difficult and the failure rate is high. Failure means that
the project ends with a lower scope or quality, higher expenses, or a longer
time frame than planned. If these parameters would have been known at the
beginning many of such projects would not have been started in the first place.
In addition and on top of all these bad news and even though all the best
efforts exercised by the team in most cases the customers end up quite
dissatisfied — and the same holds true for the project team.`}</p>
      <p>{`To reduce the risk of such situations, status reporting for projects is
absolutely essential. If the project has a higher complexity than just a very
low one, you need to structurally collect and analyse data. Otherwise you are
losing the overview and manageability of the project.`}</p>
      <h4>{`Structural information in a project status report`}</h4>
      <p>{`Without the structural information from a project status report you are like a
pilot who is flying an aeroplane that does not have instruments. You are
basically flying blind. No pilot would ever enter such an aeroplane, but yet
still enough project managers would embark on the journey of a project without
having regular status reports.`}</p>
      <p>{`THE PROJECT STATUS app has been designed to support proper and rigorous project
control.`}</p>
      <p>{`Even worse than no status report is a bad status report. To stay in the picture
of the pilot, only worse than no instruments are instruments that are
misleading. They actually trigger the wrong actions. Unfortunately, there are
many project reporting templates in practice, that do not even fulfil the basic
requirements of project status reporting: a unique responsible for each
deliverable, a unique recipient who assesses the quality of the deliverable and
a defined traffic lights scheme that displays the status with regards to
delivery dates, budget and time constraints.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.750000000000002%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAdUlEQVQI1zWMyw6DQAwD+f+v7KEPtWqBBcJmSWxXaMXcbHk8mNOcWwVASWtlb9ZKnsUJqK1yWRxAj10ZiqHs/Ey1HSHJD86G2fid9ojsMqlxw+N2D3dJR3I2/koMifPJW2Rm3yWYlE1PRNWFt3iN70BcG7XAH9HRr8cNCpQiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Important Ratios",
            "title": "Important Ratios",
            "src": "/static/70eab9226a0a408ea402d4dbddf890fa/78d47/project-status-org_ratios-important.png",
            "srcSet": ["/static/70eab9226a0a408ea402d4dbddf890fa/9aebd/project-status-org_ratios-important.png 480w", "/static/70eab9226a0a408ea402d4dbddf890fa/78d47/project-status-org_ratios-important.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`Instead, there are project reports that show an overall green status, even
though the project is only halfway through and the budget is already 90% used
up. There are many more of such deficiencies that can take place in status
reporting.`}</p>
      <p>{`In order to avoid such basic deficiencies and to make THE PROJECT STATUS as
useful as possible we the app was based on the
`}<a parentName="p" {...{
          "href": "/concepts/10-commandments-project-control"
        }}>{`10`}{` `}{`Commandments of Project Control`}</a>{`.`}</p>
      <p>{`But, please, evaluate the usefulness of the report for yourself — and let me
know what you think. You can reach me at: prof.anders@online.de.`}</p>
      <p>{`Before you start using the app I also recommend that you quickly read further
through the remaining infos so that you are aware of the concept and usage of
this app.`}</p>
      <h2 {...{
        "id": "current-version-agile-development-and-best-screen-size",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#current-version-agile-development-and-best-screen-size",
          "aria-label": "current version agile development and best screen size permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Current Version, Agile Development and Best Screen Size`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.499999999999996%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAsUlEQVQY02N4tXbui8UTn05vejaj5enM5qczmp9Oh5LP53X/+fD2///////9+48NMNwuDL2VF3Qry/dmtv+t3MBbOf43s3xv5QXeTPe8Fmvz48k9fJrvVsTdrUq4W514tyrhXk3S3cqEe7XJ92pT7pRG30h2/vn8IT7Nt/KCbheE3MoJuJnlDbU2NwBi89UIcwI2v1gy6cXiic/ndEC8/Ww6xM8tz6Y3PZvZ+vv9GzyaAQcB7To6HFstAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Test Version",
            "title": "Test Version",
            "src": "/static/80b7ca666f090f1b5745a2159e6e7f32/78d47/project-status-org_test-version.png",
            "srcSet": ["/static/80b7ca666f090f1b5745a2159e6e7f32/9aebd/project-status-org_test-version.png 480w", "/static/80b7ca666f090f1b5745a2159e6e7f32/78d47/project-status-org_test-version.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`THE PROJECT STATUS app is currently in TEST VERSION 0.17.6.alpha. Alpha means
that the app is still under testing and development. The app has already been
properly tested, but some errors or some unexpected behavior may remain. The app
is online in this early stage to gain experience and to understand critical user
behavior or assumptions. Feel invited to already use the app but exercise
special care with your data by downloading them regularly.`}</p>
      <p>{`The app has been developed in a completely agile manner. The plan is to continue
the development of this app based on further research and on your feedback. In
case you are missing a critical feature or would like to suggest an improvement,
please send a message to: prof.anders@online.de.`}</p>
      <p>{`The app is not optimized for smaller screen sizes yet and it will most probable
never be useful on a smartphone. So, I recommend that you use a screen size of
at least 1280px in width but wider is better. Below that screen size you may
experience distortions.`}</p>
      <h2 {...{
        "id": "disclaimer",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#disclaimer",
          "aria-label": "disclaimer permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Disclaimer`}</h2>
      <p>{`You cannot hold the provider of this app liable in case any of the calculations
are wrong, mismatching or based on false assumption. Be aware, that any planning
is always a simplification of reality and any planning tool needs to make
assumptions in order to reduce complexity. You are using THE PROJECT STATUS app
at your own discretion and risk and you need to perform your own quality
assurance on whatever planning you are performing. If you are in doubt about its
correctness or security, don’t use THE PROJECT STATUS.`}</p>
      <h2 {...{
        "id": "design-concept",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#design-concept",
          "aria-label": "design concept permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Design Concept`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.58333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAACkUlEQVQ4y41UaW8kKQyt///T5tMemaSTSTrpq44uoMAcNmBgVNU9yUq7Ws2TZansen62QXQPf/3x9Pj89Ph0PJ6YuWzgzMEHDOh8IIpIRBQpJmsdgF29dcZAdxrcMPtR+PEaRuFTyinlGDOujMiZb8gbYkw3S5vvphmEAADnPXqPpZT2D6S00krh9l/ojCUDiBhrq7XUWlqtdyu1IWVahfJnpLZf2VI7qRxS+iwmlE/prlNbcyFnvvfCXKYZKOYv5em6aOPzOmdMKSmpvQt3cq0Ajn+RSynGuJy/RuicTzkzZ86bESXmr3TiWmr7bITLau2zbecQMadcYirMNcZUSq213jxiZL59ry7lwluxOzmnNEpUJriAQlOKJBYEF40nZShnFkswEGKMBgIhgg0Y/L3t9SDWNdZtJWtJLm1TaBRXfUoV07okxDWKsaRI68+ldDlzojxdlFJm0bBoQ0Tbifwf6oaOSwkufv92XGaorZZVbJ2v/QY62g7ZOBwuUk02ANVthvuV+JdtwTu6+nUfq539fDLR2tb4t5R/jOHHiHuR97Icob3KuuvT85g+FO9FeRN8UOWgyocse8F7wQ8n/35R7+fr/jx3u7N96f3jUT+e7N/vy67Hl4FeBtodzZbC54N+OonXyb9N9DrirsePy/U8TIfT0HlrS46ghDdai7kylUSVkzOGvK05KS21myl6Lilz5EzBO0LEgJ2U2ocgpVoWPY5TCLdXgJbFWOsCxknMAiRcB9f37nL2SlnrLFgA6MxiMeA0TotS4zACQPCBAolJSCkJoxbSaglvL+bhz7D7jmNv7Mo0xnSL9og09oMU8uP9oLUOIVgI0zALIZDIWRfMfO6H/eE0ztKABQMAoLX5Cevpgwfzvp0DAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Everything on one page",
            "title": "Everything on one page",
            "src": "/static/497994d7e407be5e95ad82fab978aaa7/78d47/project-status-org_everything-on-one-page.png",
            "srcSet": ["/static/497994d7e407be5e95ad82fab978aaa7/9aebd/project-status-org_everything-on-one-page.png 480w", "/static/497994d7e407be5e95ad82fab978aaa7/78d47/project-status-org_everything-on-one-page.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <br />
      <p>{`The structure of THE PROJECT STATUS app is based on the following design
concept:`}</p>
      <ul>
        <li parentName="ul">{`Everything important should be on the same page, which is the Status Sheet
page.`}</li>
        <li parentName="ul">{`This central page should combine the executive and the operational
perspectives. This means that the executive summary is a direct result of the
vertical aggregation of the underlying operational information.`}</li>
        <li parentName="ul">{`All numbers and calculations should be easy to comprehend, logical and
re-calculable.`}</li>
        <li parentName="ul">{`Each status report is based on a defined status date. One can travel through
all previous status dates to review the project evolution.`}</li>
        <li parentName="ul">{`The look and feel should not differ between agile (KANBAN, SCRUM) or
traditional project methodologies.`}</li>
        <li parentName="ul">{`There is a progress tab with a chart based project report to get a quick
overview where the project stands and what status has been achieved so far.`}</li>
        <li parentName="ul">{`As project business is people business the Status Sheet is complemented by a
third page, that displays all details of the persons involved in the project.`}</li>
      </ul>
      <h2 {...{
        "id": "printing-and-distribution",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#printing-and-distribution",
          "aria-label": "printing and distribution permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Printing and Distribution`}</h2>
      <p>{`A project status report is only useful if it is consumed, read and analysed by
the people involved in a project and if it then triggers the necessary
activities to make the project successful.`}</p>
      <p>{`For that reason the distribution of the status report is vital.`}</p>
      <p>{`The recommended way of doing this is to generate a PDF from the Status Sheet
page on the current status date. This also has the advantage that the project
controller of the project owns the single source of truth and is in full control
of the data and the delivery of the report.`}</p>
      <p>{`The distribution of the report takes place as a push process, e.g. by email or a
messenger. Experience has shown, that a push process works in this case better
than a pull process where the receiver has to make the effort to first log in
into an app before (s)he can get the report.`}</p>
      <p>{`There are a number of tools that allow for the generation of PDFs from a
webpage. `}<a parentName="p" {...{
          "href": "https://getfireshot.com/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Fireshot`}</a>{` has received very good reviews but
THE PROJECT STATUS does not have any further connection to this app or its
company. So, please, judge for yourself and select a solution that suits you
best.`}</p>
      <h2 {...{
        "id": "local-data-data-security-and-working-with-several-projects",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#local-data-data-security-and-working-with-several-projects",
          "aria-label": "local data data security and working with several projects permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Local Data, Data Security, and Working with Several Projects`}</h2>
      <p>{`THE PROJECT STATUS keeps all your data local. So there is no connection to a
central app or database. The advantage of this approach is two-fold:`}</p>
      <ul>
        <li parentName="ul">{`You do not need to sign in or log in into a central server solution.`}</li>
        <li parentName="ul">{`All your project data are on your own hard drive under your own
responsibility.`}</li>
      </ul>
      <p>{`When you work with a project within THE PROJECT STATUS app your data are stored
in the local storage of your browser. This means, as long as you are using the
same browser, you can continue to work with your data. This feature is called
persistency. Should you clean or reset your browser your data will be lost. For
that reason it is recommended that you download your data for safety reasons
after each working session in which you have made changes.`}</p>
      <p>{`Your browser can only store and work with one project at a time. If you want to
work with several projects at the same time, you need to download the data after
your work with the project has finished and upload the data before your work
with another project starts.`}</p>
      <h2 {...{
        "id": "download-upload-and-sensitive-data",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#download-upload-and-sensitive-data",
          "aria-label": "download upload and sensitive data permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Download, Upload and Sensitive Data`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIElEQVQI12P4+vXr+/cfvn379vv378+fv3z9+vU/0QAA+rk7EKZR4MAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Download and upload",
            "title": "Download and upload",
            "src": "/static/55d03e9a6c2d2b53787c9ca1a3a178c9/78d47/project-status-org_download-upload.png",
            "srcSet": ["/static/55d03e9a6c2d2b53787c9ca1a3a178c9/9aebd/project-status-org_download-upload.png 480w", "/static/55d03e9a6c2d2b53787c9ca1a3a178c9/78d47/project-status-org_download-upload.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`You can save all your project data by downloading it. You should do this
regularly to prevent any data losses. The download file name has your project
name and adds a timestamp so you know when you downloaded your data.`}</p>
      <p>{`The download function also helps you in case you want to collaborate with
another person on generating or managing a project plan because you can pass on
this download file for upload.`}</p>
      <p>{`The download file is not encrypted so you can read it by help of an editor of
your choice. If you modify the data in the file, you are corrupting it and it
cannot be uploaded any longer. But in any case, you should only upload files
from trusted sources.`}</p>
      <p>{`The download file will contain all your project data. Some of these data,
including the personal data, may be sensitive. As all data are local, it is your
own responsibility to ensure proper encryption and storing of these data,
especially if you pass these data on to another person.`}</p>
      <h2 {...{
        "id": "reset-factory-reset-and-crashes",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#reset-factory-reset-and-crashes",
          "aria-label": "reset factory reset and crashes permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Reset, Factory Reset and Crashes`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.041666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAKUlEQVQI12NYtvvhyj2PNh5+cubGu2W7Hq7c++jOk8//////9+8/QQAARmQ3h/e084EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Reset",
            "title": "Reset",
            "src": "/static/35fa6245abf858a1b2b08bd5110f3c47/78d47/project-status-org_reset.png",
            "srcSet": ["/static/35fa6245abf858a1b2b08bd5110f3c47/9aebd/project-status-org_reset.png 480w", "/static/35fa6245abf858a1b2b08bd5110f3c47/78d47/project-status-org_reset.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`If you want to setup a new project you can click the ‘Reset Project’ button
either on the Setup page or the Status Sheet page. The ‘Reset Project’ button
deletes all your project setup, deliverables and persons.`}</p>
      <p>{`Alternatively you can click the ‘Reset Sheet’ button on the Status Sheet page.
The ‘Reset Sheet’ button deletes only the sheet including your deliverables but
keeps persons and the general project setup.`}</p>
      <p>{`In case you want to completely reset THE PROJECT STATUS you can also use the
Factory Reset. You will find the Factory Reset button down on the left in the
page footer. In the very unlikely event that the app should crash and in case
you cannot get it back by refreshing your browser a factory reset should also
remedy this situation. You can also reach the Factory Reset through this link:`}</p>
      <p><a parentName="p" {...{
          "href": "https://app.project-status.org/factory-reset",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://app.project-status.org/factory-reset`}</a></p>
      <p>{`In case you can reconstruct the error that led to the crash, please let me know
the source of the problem, so that I can fix it. You can reach me at:
prof.anders@online.de.`}</p>
      <h2 {...{
        "id": "colors",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#colors",
          "aria-label": "colors permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Colors`}</h2>
      <p><a parentName="p" {...{
          "href": "https://www.materialui.co/colors",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}><span parentName="a" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "30%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9UlEQVQY02N4d+DQuZ0P9p56e/jhj8N3rtx+vv/Hlz2/7q2/f2LP5Ru3f//58x83YHi4cO3euVeW7ni++Nq3ZRePnbu/4vuHBd8vdt7YseL8tds/fv7Cp/lceGyj57rQsovJG9+kLJ2w8EDE24d+n1ZqrykI7J6x8Ov3H/////+HS/NqF+8o0xlWoWu9mnZ4ViSX9ttcXWf5oku51l3HKyz22/fvIM3/sGtnOB2e1BF43S/7YebaB00n1y4/H/P6psvLuWZnF3Sdvnz9z2+8zn7Y0bel/XvbnP+zz/5Z8/z88U8dXz9Xfdie9fnCAZD83794NAMAGTQq34wpl7cAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Colors",
              "title": "Colors",
              "src": "/static/170d238c37e617bc24e1678bd18511dc/78d47/project-status-org_colors.png",
              "srcSet": ["/static/170d238c37e617bc24e1678bd18511dc/9aebd/project-status-org_colors.png 480w", "/static/170d238c37e617bc24e1678bd18511dc/78d47/project-status-org_colors.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></a></p>
      <p>{`THE PROJECT APP uses the Material color scheme as defined by Google and as
summarized by `}<a parentName="p" {...{
          "href": "https://www.materialui.co/colors",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`materialui`}</a>{`.`}</p>
      <p>{`A core element of THE PROJECT STATUS app is the traffic light with the extended
colors of red, orange, amber, and green. In order to reserve the traffic light
colors for the traffic Lights THE PROJECT STATUS uses non traffic light colors
for all other purposes, especially: blue, purple, pink, brown, and blue-grey.`}</p>
      <h2 {...{
        "id": "persons",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#persons",
          "aria-label": "persons permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Persons`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiElEQVQI1yWNuxHCQAwF3TJ90AMd0AEpCSkZGRnYhz32faR3TxIz9kY7m+zwejzH9yciAOuMiKC5qMVOkw7wiNqde1aQuw2X0/l+vTXGd1zSvJEEugjM3cy2XEqpEZ5zTb815yKi4zSnKdXahnVa6laaKoDahGSER/jB8Xd30qSJiKB3oANQ1T97TqznBD1PsQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Persons",
            "title": "Persons",
            "src": "/static/7f4d8187c278e15d3496b6009bf53a2c/78d47/project-status-org_persons.png",
            "srcSet": ["/static/7f4d8187c278e15d3496b6009bf53a2c/9aebd/project-status-org_persons.png 480w", "/static/7f4d8187c278e15d3496b6009bf53a2c/78d47/project-status-org_persons.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`People are the most critical resource for every project. For that reason, THE
PROJECT STATUS offers a separate page for them. Often, when you have complex
projects a lot of people with different backgrounds or skills, or from different
companies meet. Therefore, it is important to keep the overview of the who is
who.`}</p>
      <p>{`For each person you can add the most relevant data. If you enter the roles of a
person here, it will also automatically show up one the Setup page and vice
versa.`}</p>
      <p>{`If you are entering personal information make sure that you seek consent from
each person and that you adhere to any data protection law. For easier
recognition of project members you can also add an Avatar-URL.`}</p>
      <p>{`New persons can also be entered through the person related forms on the Setup or
on the Status Sheet page. All persons entered at these places will automatically
be added to the persons list.`}</p>
      <p>{`Finally, on the Persons page you can also decide if you generally prefer first
names or last names for your project.`}</p>
      <h2 {...{
        "id": "status-dates-and-status-travel",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#status-dates-and-status-travel",
          "aria-label": "status dates and status travel permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Status Dates and Status Travel`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAALklEQVQI12N49f7bi7efX3/88ebj99cfv7359OP1h2+vP3x7/+3fh+/40JtPPwHGXTk27vWAsgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Status Travel",
            "title": "Status Travel",
            "src": "/static/fd07a626beee5a72a82a5da4f9dc5f23/78d47/project-status-org_status-travel.png",
            "srcSet": ["/static/fd07a626beee5a72a82a5da4f9dc5f23/9aebd/project-status-org_status-travel.png 480w", "/static/fd07a626beee5a72a82a5da4f9dc5f23/78d47/project-status-org_status-travel.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`The Current Status Date is a crucial concept for THE PROJECT STATUS app. At
every Status Date the project status should be evaluated. Ideally, the status
report will then be sent out to all involved persons in the project at the end
of the Current Status Date (or if not all information could be collected because
of the complexity of the project a day or two later).`}</p>
      <p>{`If you have the freedom to pick, a good weekday for a status report is a
Wednesday or Thursday, so that people can review the status on a Friday and
start taking actions on the upcoming Monday.`}</p>
      <p>{`THE PROJECT STATUS app supports the work of a project controller by
automatically setting the DL — Deadline traffic lights. All DELIVERABLES that
have a BY WHEN date that end before the Current Status Day are automatically
evaluated. For all other deadlines that are on or after the Current Status Date,
the project controller should collect the information on the % - Degree of
Completion, the FC — Forecast if the deadline will be reached in time, and the
Quality assurance of every DELIVERABLE that has already been delivered.`}</p>
      <p>{`Once the report for the Current Status Date is completed you want to make the
Planned Next Status Date the new Current Status Date. You can do this in the
Setup page (please see the description over there for more details).`}</p>
      <p>{`The Current and all previous Status Dates show in the light blue header line.
You can click on every date and thus travel through the past statuses of your
project to see the evolution of your project or for review purposes. Information
from all past Status Dates are locked and cannot be changed. You can only change
the information that belongs to the Current Status Date.`}</p>
      <p>{`On the right hand side is a symbol for a dustbin. When you click on it, you will
delete the Latest Status Date. You can then build a New Status Date based on the
information of the Previous Status Date.`}</p>
      <h2 {...{
        "id": "project-setup",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#project-setup",
          "aria-label": "project setup permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Project Setup`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGUlEQVQ4y42S647cIAyF8/4vWGl/tNVeZpJJwsUEfMEmFcnu7KiXVT8dRQaDOQQP3366p2f3umwXT7et/ae+j+npxQ0/Ln6c0zSHmBnrXqQd2jO3L0S6Y23D29WNN59iPLUBnIGqtma/y/QzMB1Ed6mttb0d7Af34E8eE0MAVGt2oGY+ZmI5K5lZoT7ZPoYu5Iz1Phw80IaaimZUMyOiWqt9sO/NHiAi64bfGa5zSmiHnaZq01qg6L32ZYIlYDsuYmbXG6wBTyt98/PrvHiUfpiKyDStIWbV7kJVvQeWT5/z4iOgfpw9XCc/zeBDhAg5F0LM24al9NVqGWtBqdJR1fe/ebc9z86HBJASJGGRqlJVVatqrdU5WNYYAiSAnLOIMtdOz+vgE4eNkYSFEclFWgNmZGYqBddQPHAqLCKItLi8BEyZkLqGZV4uq9zW1F01DRByqT6kWutuFt26QnUhm6rtbY6xkPqQRWp/57fLbbyFefHB+xiid847750PIbrVQYzMQkTMzCLM1D8nIsPsUkxnkonYA62R4kZMjIjzmnI57kCESAEol16KDoZxioXqZ8tV2YWbyNmktxnSVs6MtZZJ7aE/B+98KXgUO+vxo7YtJziNyeH/ff5kUFWpVeQvYhYPmEofnDtXt3kgYjkZ9n9jrV3GOK64UTub/DqG67xlOluufbVZ1cZxyZmk9pdS1QgbdCd2tK/9AgBlUF3ztAg2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Setup",
            "title": "Setup",
            "src": "/static/63e207c640cbbcea945167b427aad950/78d47/project-status-org_setup.png",
            "srcSet": ["/static/63e207c640cbbcea945167b427aad950/9aebd/project-status-org_setup.png 480w", "/static/63e207c640cbbcea945167b427aad950/78d47/project-status-org_setup.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <br />
      <p>{`THE PROJECT STATUS supports classical (waterfall) projects as well as agile
(KANBAN or SCRUM) projects. All general project information can be entered on
the project Setup page. Your changes are taken over whenever you are leaving an
input field or when you press Enter.`}</p>
      <p>{`The Setup page has four sections:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`General Information`}</strong>{` describing the project and the role of persons
involved. If you have previously setup the persons involved in the project in
the Persons page you can now select them via the menu. If you add persons
there the first time, they will automatically show in the Persons page, where
you can later add more details if you want.`}</p>
          <p parentName="li">{`Classical and agile projects usually make use of different roles. THE PROJECT
STATUS supports both methodologies. If you are not filling in a role, this
field will later not show in the executive summary.`}</p>
        </li>
      </ul>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`External Ratios`}</strong>{` are ratios that are not computed from within THE PROJECT
STATUS. These are Expenses or Satisfaction figures which need to be
supplemented from outside.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Date and Day Information`}</strong>{` are essential to manage Dates. At first, you can
select the Start Date of your project. Later you can manage your Status Dates
in this section. It shows you your Current Status Date. You can also plan for
the Next Status Date to announce the Planned Status Date to the persons
involved. If you want to turn a Planned Next Status Date into a Confirmed Next
Status Date you need to first set the Current Status to ‘Is Completed’ before
you can enter a Confirmed Next Status Date. Be careful, once you set the
Current Status Date to ‘Is Completed’ all of its values will be locked.`}</p>
        </li>
      </ul>
      <ul>
        <li parentName="ul">{`The `}<strong parentName="li">{`Parameters`}</strong>{` allow you change the coloring of the traffic lights in the
ratios section. The numbers are the borders when the traffic light colors
would change. For instance 0, 50, 70, 90 would mean: change from red to orange
at 50%, change from orange to amber at 70% and change from amber to green at
90%. In this sections you can also change the currency symbol.`}</li>
      </ul>
      <h2 {...{
        "id": "status-sheet",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#status-sheet",
          "aria-label": "status sheet permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Status Sheet`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAd0lEQVQY05XOuw4CIRCF4X3/57MxMTESQ3CXiLvAXICZMTYWVvh3p/iSs6wJfYTHE0OE+CIX6pZQRWyi5b7W8213oZwuyW9w9dmFzMxTmKiXyoANqQP21oaZqeoUPkoD6t+tZjInPzgXPgqL6E9zt3nsmYmH/d8be2tiFl4NLvIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Status Sheet Infos",
            "title": "Status Sheet Infos",
            "src": "/static/f9c477670b07b5e592ad4d37a9eb3af0/78d47/project-status-org_status-sheet-infos.png",
            "srcSet": ["/static/f9c477670b07b5e592ad4d37a9eb3af0/9aebd/project-status-org_status-sheet-infos.png 480w", "/static/f9c477670b07b5e592ad4d37a9eb3af0/78d47/project-status-org_status-sheet-infos.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`The Status Sheet holds the central information of the project status.`}</p>
      <p>{`It is comprised of three layers:`}</p>
      <ul>
        <li parentName="ul">{`the executive layer,`}</li>
        <li parentName="ul">{`the visualization layer,`}</li>
        <li parentName="ul">{`the operational layer.`}</li>
      </ul>
      <p>{`The executive summery layer is comprised of four parts:`}</p>
      <ul>
        <li parentName="ul">{`the Project Title with the Objective & Description,`}</li>
        <li parentName="ul">{`the Customer(s), Persons & Team, i.e. the involved persons in their assigned
roles`}</li>
        <li parentName="ul">{`the most relevant Dates, and`}</li>
        <li parentName="ul">{`the important Ratios of the project.`}</li>
      </ul>
      <p>{`All information can be folded in or out.`}</p>
      <p>{`In the operational layer you can enter your day-to-day project activities and
here the information is aggregated up from individual task to the whole of the
project. The whole project is then finally summarized in the executive summary
layer.`}</p>
      <p>{`In between the executive and the operational layer is the visualization layer in
form of the Gantt-Chart, which can also fold in or out. The Gantt chart
represents the individual deliverables that are specified in the rows of the
project sheet.`}</p>
      <p>{`With its visualization, the Gantt-Chart can support both layers the executive
one as well as the operational one. This is why it has been chosen to sit in
between those two perspectives.`}</p>
      <p>{`Under the Gantt-Chart follows the actual project sheet that holds the individual
deliverables, responsibles, and dependencies of the project — one per row.`}</p>
      <h3 {...{
        "id": "rows-deliverables-and-milestones",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#rows-deliverables-and-milestones",
          "aria-label": "rows deliverables and milestones permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Rows, Deliverables and Milestones`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.583333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfElEQVQoz5XQUQ6DMAyDYe5/USZKoVmbuE4qbWInWPz+6Ze8AThKPc4KTDMj+fl7m7S276Vel4i01lQ1gefELZA+1wr3WGslMIAuBtDdnZ7DEzir9jEjnGlMinSRN/nEIyKBSd61lNduZmmsqreMJoO/ZctzGBVPMyL39hegKRRB7+lnNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Aggregation",
            "title": "Aggregation",
            "src": "/static/7f060f7e4dc6f96bef4eaa95f19268ae/78d47/project-status-org_aggregation.png",
            "srcSet": ["/static/7f060f7e4dc6f96bef4eaa95f19268ae/9aebd/project-status-org_aggregation.png 480w", "/static/7f060f7e4dc6f96bef4eaa95f19268ae/78d47/project-status-org_aggregation.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`Rows in the status sheet reflect project deliverables. There are three types of
rows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Individual rows`}</strong>{`, that are not aggregated. Each individual row reflects a
project deliverable.`}</li>
        <li parentName="ul"><strong parentName="li">{`Aggregated rows`}</strong>{`, that aggregate underlying rows, which again can be
aggregated rows or individual rows. Aggregated rows reflect either a set of
deliverables or a list of deliverables.`}</li>
        <li parentName="ul">{`The `}<strong parentName="li">{`root row`}</strong>{`, named PROJECT, which is the first row and which aggregates
everything.`}</li>
      </ul>
      <p>{`Aggregated rows have a little triangular at the beginning which is called a
`}<em parentName="p">{`caret`}</em>{` `}<BiCaretRight style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="BiCaretRight" />{`. In
order to keep the overview any aggregated row can be folded or unfolded by
clicking on the caret icon.`}</p>
      <p>{`An aggregated row is a parent to zero or more children rows, that means
aggregated rows can also have no sub-rows. In this case the Caret shows in grey
instead of the position color.`}</p>
      <p>{`As a general rule, the color scheme goes from more dominant to less dominant
colors the lower an item gets in the hierarchy, i.e. the longer its position
becomes.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAANUlEQVQI1yWJ2wYAIBAF+/+fjKLdLrKX50OOGMOYMgztYgbpF/Uw1bESGhhGi9Ma2Mn1UxwPulc4ml9vhCAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Who Delivers What",
            "title": "Who Delivers What",
            "src": "/static/78390d882cfc3664baf38d7181be35b3/78d47/project-status-org_who-delivers-what.png",
            "srcSet": ["/static/78390d882cfc3664baf38d7181be35b3/9aebd/project-status-org_who-delivers-what.png 480w", "/static/78390d882cfc3664baf38d7181be35b3/78d47/project-status-org_who-delivers-what.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`You can enter WHO, DELIVERS WHAT, BY WHEN, TO WHOM, SPENT, % Degree of
Completion, FC — Forecast, Q — Quality, a COMMENT and PRECS into each individual
rows. WHO represents the OWNER of a DELIVERABLE. The DELIVERABLE can be an
individual item or — on an aggregated level — be a set of DELIVERABLES. PRECS is
short for PRECEDENTS and represent deliverables that need to be completed before
work can start on the current deliverable.`}</p>
      <p>{`Each BY WHEN is pinnable. Pinning a BY WHEN is the improved version of a
milestone. If you pin a BY WHEN, then the BY WHEN date cannot move any longer to
the back. If you pin an aggregated row, no children can move beyond the pinned
date.`}</p>
      <p>{`THE PROJECT STATUS aggregates your information into the aggregating rows above
and fills BY WHEN, SPENT, % Degree of Completion, DL — Deadline, FC — Forecast,
Q — Quality automatically. For that reason you can only enter WHO, DELIVERS
WHAT, TO WHOM, and a COMMENT in aggregated rows.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.708333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVQY05XOOw7CMBBFUe9/OxRUlFRIFOACpAiREBIRiPGMPdhM/GELzOuP3lW7Nm+btNa8OvDmxJxK/XtKt3bfGN2H4y2cx5iyBHcj3mcyno1noKUIbFWXia9zGrD0Ng8gOq6KnPfolhgDkQOsIuwmgIf9vvHzAnxikXT/AKHsX59hi07xAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Position",
            "title": "Position",
            "src": "/static/d7692b2ac4eb23a034368a9b06132e59/78d47/project-status-org_position.png",
            "srcSet": ["/static/d7692b2ac4eb23a034368a9b06132e59/9aebd/project-status-org_position.png 480w", "/static/d7692b2ac4eb23a034368a9b06132e59/78d47/project-status-org_position.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`Each row shows a POSITION. Positions are automatically calculated and show the
hierarchical level of your DELIVERABLE.`}</p>
      <p>{`If you click a row the row highlights in light pink. If you then click on a
position, the row will underneath turn into a light blue. This can help you
managing the overview or, alternatively, in Kanban depict a fast track for
urgent issues.`}</p>
      <p>
  Sometimes certain DELIVERABLES cannot be resolved from within the project
  team. In these circumstances you can press the round button under the{" "}
  <BiBlock style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="BiBlock" />
  . The button turns red and the row will be greyed out. The red color reflects an{" "}
  <em>Unresolved Issue</em>. No value from an unresolved issue will be aggregated
  up.
      </p>
      <p>
  If you want to ignore a row you can click the round button{" "}
  <BiBlock style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="BiBlock" />{" "}
  again. The button will turn from red to black. The row will continue to be
  greyed out. In this case only the SPENT value will be aggregated. All other
  values will be ignored. The reasoning for this behavior is that you generally
  want to keep all issues in the sheet and not delete any in order to have a
  complete project history. In case a DELIVERABLE was not delivered in a
  satisfactory manner in the first delivery but then in the second, you do not
  want to aggregate up the red traffic light from the first issue. But still you
  need to recognize the effort SPENT for achieving the first delivery.
      </p>
      <p>{`Note, if you shift click you circle through the states backwards.`}</p>
      <p>{`Each row is represented by a Gantt-Bar in the Gantt-Chart. Read more about it in
the Gantt-Chart section.`}</p>
      <h3 {...{
        "id": "agile-and-traditional-kanban--scrum",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#agile-and-traditional-kanban--scrum",
          "aria-label": "agile and traditional kanban  scrum permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Agile and Traditional (KANBAN & SCRUM)`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.041666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGklEQVQI12N4/urjrYcfH7389v///3//SQMA8086/JydeDkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kanban",
            "title": "Kanban",
            "src": "/static/f69406bbf32f881d1ebbb2ff00621181/78d47/project-status-org_kanban.png",
            "srcSet": ["/static/f69406bbf32f881d1ebbb2ff00621181/9aebd/project-status-org_kanban.png 480w", "/static/f69406bbf32f881d1ebbb2ff00621181/78d47/project-status-org_kanban.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`Traditional project methodologies reduce the complexity by making a project
breakdown structure. Then individual deliverables are planned in each element of
the breakdown structure. So the information hierarchy the traditional
methodology is: project → breakdown structure element → deliverables.`}</p>
      <p>{`Agile methods usually use KANBAN boards. KANBAN boards can be used for the
KANBAN project methodology or for SCRUM. KANBAN boards are comprised of lists
and cards. Cards contain the deliverables. Lists reflect a status of the
deliverable. Thus the status of a card (with a deliverable) is reflected by the
list it is on. The card travels to a different list if its status changes, e.g.
from NYS to WIP to DONE. As a result the information hierarchy in an agile
project is: project → list representing a status → deliverables.`}</p>
      <p>{`Even though the logic is different, structurally both approaches, agile and
traditional, break down the project into parts which then contain the individual
deliverables.`}</p>
      <p>{`THE PROJECT STATUS combines this realization and invents VERTICAL KANBAN. The
representation of a project in rows can then be used for both purposes.`}</p>
      <p>{`In order to make both alternative possibilities more transparent, there is a
KANBAN switch. When you switch into KANBAN mode, DELIVERABLES (ITEMS or SET)
turn into LISTS & CARDS (FOR DELIVERABLES). Such lists are typically named
`}<em parentName="p">{`Product Backlog`}</em>{`, `}<em parentName="p">{`Sprint Backlog`}</em>{`, `}<em parentName="p">{`Specify`}</em>{`, `}<em parentName="p">{`Implement`}</em>{`, `}<em parentName="p">{`Validate`}</em>{`.
Typically you then distinguish two sub-lists under each list: `}<em parentName="p">{`WIP`}</em>{` — Work in
Progress and `}<em parentName="p">{`DONE`}</em></p>
      <p>{`In traditional project methodologies deliverables typically do not change
positions. In KANBAN, however, the status of a deliverable is also visualized by
moving the deliverable into a different list. In order to not lose the card when
it is travelling, the KANBAN mode also shows a short identifier.`}</p>
      <p>{`In traditional project management deliverables usually also show the precedents,
that need to be completed before the work on a deliverable can start. In agile,
the work on certain deliverables can become blocked by other deliverables. For
that reason, KANBAN mode turns PRECS into BLOCKS.`}</p>
      <h3 {...{
        "id": "wip-and-wip-limits-nys--not-yet-started",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#wip-and-wip-limits-nys--not-yet-started",
          "aria-label": "wip and wip limits nys  not yet started permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`WIP and WIP Limits, NYS — Not yet started`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.458333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAMUlEQVQI153LQRUAIAhEQfrns4EBPCE+xf1mkLmPtT6HJyC+WW5HSYlFrDzVzBVSLT9tde5no65XCAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Formats",
            "title": "Formats",
            "src": "/static/67f552f308ca2f6bdd4ba479f510d000/78d47/project-status-org_wip.png",
            "srcSet": ["/static/67f552f308ca2f6bdd4ba479f510d000/9aebd/project-status-org_wip.png 480w", "/static/67f552f308ca2f6bdd4ba479f510d000/78d47/project-status-org_wip.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>
  If work has started on a deliverable with SPENT {">"} 1 and DEGREE {"<"} 100%
  a hourglass{" "}
  <IoHourglassOutline style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="IoHourglassOutline" />{" "}
  appears. This is WIP — Work In Progress.
      </p>
      <p>{`If not ignored or unresolved, each deliverable has one of three statuses: NYS —
Not Yet Started, WIP — Work In Progress, DONE — 100% completed.`}</p>
      <h3 {...{
        "id": "entering-persons",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#entering-persons",
          "aria-label": "entering persons permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Entering Persons`}</h3>
      <p>{`When you click on the fields WHO or TO WHOM, you can type in the responsible
person or select it from the pull down menu. If the person is not found, it will
automatically be added. Should you want to correct a misprint or should you want
to add further details you will immediately find this person in the Persons
page.`}</p>
      <p>{`According to the
`}<a parentName="p" {...{
          "href": "/concepts/essence-project-management/"
        }}><em parentName="a">{`Essence of Project Management`}</em></a>{` you
should always have one unique person responsible for each DELIVERABLE and one
for receiving and quality assess the DELIVERABLE. For that reason you can only
enter one person in such fields.`}</p>
      <p>{`Be default the rows are sorted by positions. As an alternative you can sort the
columns alphabetically by the names of the owners in the WHO or TO WHOM columns.
For that you can click on the `}<FaSortNumericDown style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="FaSortNumericDown" /> icon.
The icon then changes into <FaSortAlphaDown style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="FaSortAlphaDown" />{` and the
rows are re-ordered. In the alphabetical sorting you cannot move, add or delete
a row any longer.`}</p>
      <h3 {...{
        "id": "business-days-and-hours",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#business-days-and-hours",
          "aria-label": "business days and hours permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Business Days and Hours`}</h3>
      <p>{`THE PROJECT STATUS uses a business week with 5 working days as its underlying
calendar. The business day has 8 hours starting a 9 o’clock and ending at 17
o’clock (= 5 p.m.). In the current version 0.17.6.alpha these time points are
still hard-wired, in one of the next versions you will be able to change the
start and end of day.`}</p>
      <p>{`Between two tasks that follow each other on the same day is one hour time
difference. This means, if one task ends at 11 o’clock the next task will begin
at 12 o’clock. If a tasks ends at the end of a business day a subsequent task
will begin at the start of the next business day.`}</p>
      <p>{`Bank holidays or any other form of holidays are not considered.`}</p>
      <h4>{`Dates`}</h4>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVQI15XLQQ4AEAwEQP//o7MgQlMhSrve0LlPAKDyVtumBqcwKuVYeibV586UmOuUc+H3AYwXsffLhGbOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Formats",
            "title": "Formats",
            "src": "/static/6f2a86656dc1c59f46754ccd8fefb2b4/78d47/project-status-org_date-formats.png",
            "srcSet": ["/static/6f2a86656dc1c59f46754ccd8fefb2b4/9aebd/project-status-org_date-formats.png 480w", "/static/6f2a86656dc1c59f46754ccd8fefb2b4/78d47/project-status-org_date-formats.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`THE PROJECT STATUS uses dates only in the form “yyyy-MM-dd”. However, to allow
for a fast and convenient input you can also enter the short forms “MM-dd” or
just “dd”. In such cases THE PROJECT STATUS complements the missing date
information with the information from the Current Status Date.`}</p>
      <p>{`In order to keep the sheet concise, dates are generally displayed in the
shortest from possible. If a BY WHEN is in the same year and month, only the day
will be shown. Years are only shown, if they are not the current year.`}</p>
      <p>{`You cannot enter dates that fall on a weekend. In this case the date is changed
to the Monday after the weekend.`}</p>
      <h4>{`Hours`}</h4>
      <p>{`In most projects it will be sufficient to plan on a daily basis. However, should
it be necessary to plan on an hourly basis, this is also supported by THE
PROJECT STATUS. There is a second smaller field after the date field under BY
WHEN. There you can add any number between 10 and 16 representing the hour of
the business day. Because the business day begins at 9 any number smaller than
10 does not make sense. Any number greater than 16 is considered to be the end
of the business day and is internally assigned to 17 o’clock.`}</p>
      <p>{`A date that does not show any hours refers to the end of the business day.`}</p>
      <h4>{`Durations`}</h4>
      <p>{`As a basis, durations use a week with 5 business days, a months with 22 business
days and a year with 264 business days. When durations are calculated they are
shown in the format “1y2m3w4d5” representing 1 year, 2 months, 3 weeks, 4 days
and 5 hours. A standalone number in a duration field that does not have a unit
(y, m, w, d) always represents hours.`}</p>
      <h3 {...{
        "id": "dependencies-precedents-or-blocks",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#dependencies-precedents-or-blocks",
          "aria-label": "dependencies precedents or blocks permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Dependencies: Precedents or Blocks`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.458333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAMElEQVQI12O4/ez71ae/7r3+/Z90wPD6xbM/P7/+//uLHM3fv//4+ev37z9/ydAMAFkfsfn7fwvFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Precedents",
            "title": "Precedents",
            "src": "/static/0c12845717334b96bfb928f563d889cd/78d47/project-status-org_precedents.png",
            "srcSet": ["/static/0c12845717334b96bfb928f563d889cd/9aebd/project-status-org_precedents.png 480w", "/static/0c12845717334b96bfb928f563d889cd/78d47/project-status-org_precedents.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`In the Precedents (or Blocks) input you can enter any precedent that needs to be
finished before work on a DELIVERABLE can be started.`}</p>
      <p>{`The way to input a Precedent is by using the position number of the precedent.
Don’t worry, if a position changes the Precedent information will be updated
accordingly.`}</p>
      <p>{`THE PROJECT STATUS app automatically checks for circular references. A circular
reference is for instance, if row 01 is a precedent of row 02 and row 02 is a
precedent of row 01. In this case you will get a warning message and your input
disappears.`}</p>
      <p>{`It may be helpful to understand that precedents are bequeathed and inherited up
and down the hierarchy, excluding the root row. A precedent of a row will also
be a precedent to all its descendants and all its ancestors.`}</p>
      <p>{`THE PROJECT STATUS optimizes your dependencies. If a parent row already has a
certain precedent this certain precedent will be deleted from each child of the
parent in case it was input as a precedent of this child.`}</p>
      <h3 {...{
        "id": "details",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#details",
          "aria-label": "details permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Details`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAFUlEQVQI12P4/PHj28+/f/7+TwYAAAy0O3FmZbp+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Details",
            "title": "Details",
            "src": "/static/d547728682b1c86920f593322b2bf26c/78d47/project-status-org_details.png",
            "srcSet": ["/static/d547728682b1c86920f593322b2bf26c/9aebd/project-status-org_details.png 480w", "/static/d547728682b1c86920f593322b2bf26c/78d47/project-status-org_details.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`If the switch Details is turned on you can see more details under each row.
Should you need more space to enter more precedents (blocks), here is room for
it.`}</p>
      <p>{`Read more about these rest of the details in the »Span, Projection, Slack and
From« section.`}</p>
      <h3 {...{
        "id": "adding-deleting-moving-and-folding-rows",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#adding-deleting-moving-and-folding-rows",
          "aria-label": "adding deleting moving and folding rows permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Adding, Deleting, Moving, and Folding Rows`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.041666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAJElEQVQI12N49fTdm6dvXz5+/e75u4f3Xh849+r//////hMFAMKcOe1cyXU4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Move",
            "title": "Move",
            "src": "/static/783fe2518b85c4281ca5cf01abc97586/78d47/project-status-org_move.png",
            "srcSet": ["/static/783fe2518b85c4281ca5cf01abc97586/9aebd/project-status-org_move.png 480w", "/static/783fe2518b85c4281ca5cf01abc97586/78d47/project-status-org_move.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`When you click on a row of a status sheet that is not locked, its background
highlights with a light pink. This is the active row.`}</p>
      <p>
  You can make an active row into an aggregated row by clicking on the caret
  icon
  <BiCaretRight style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="BiCaretRight" /> and vice versa. However, rows on level 1 will always be aggregated rows.
      </p>
      <p>
  You can add or delete rows by clicking on the '+' and '−' symbols. If you want
  to add a row under an aggregated row you need to click the{" "}
  <MdSubdirectoryArrowRight style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="MdSubdirectoryArrowRight" />{" "}
  icon. If a row is deleted all its descendants are also deleted. All precedent
  information to and all dependent information from this row are then also
  removed.
      </p>
      <p>{`You can move an active row. If you move a row all its descendants move with it.
By clicking on the arrow symbols rows can be moved up or down and in or out. THE
PROJECT STATUS only offers you arrows for movements that are possible.`}</p>
      <p>{`The movements are pretty predictable. There are only two edge cases:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Move in:`}</strong>{` (a) If you are moving in a row that is on a less deep level than
its top neighbor of if the top neighbor is an aggregate row, THE PROJECT
STATUS understands that you want to move this row under the top next brother
of the parent. The app then checks, if this brother is an aggregated row. (b)
If you want to `}<em parentName="p">{`move in`}</em>{` a row that is filled already and its top neighbor
cannot serve as an aggregated row THE PROJECT STATUS recognizes that an
aggregation (parent) row is missing and automatically adds one.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Move out:`}</strong>{` You can `}<em parentName="p">{`move out`}</em>{` individual and aggregated rows. With each
click they move one level up. As level 1 only permits aggregated rows, you
cannot move out individual rows onto level 1. Only aggregated rows can be
moved out to level 1.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Move up/down`}</strong>{`: If you `}<em parentName="p">{`move up (down)`}</em>{` a row that is already the top
(bottom) row in an aggregation, the row will move out of this aggregation row
into the neighboring aggregation row to the top (bottom).`}</p>
        </li>
      </ul>
      <h3 {...{
        "id": "degree-of-completion",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#degree-of-completion",
          "aria-label": "degree of completion permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Degree Of Completion`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAYUlEQVQY05WQSwqAMAwFe/+juRBvICKulLZJY9N/hJ7AzH54wzPLnrYjrAdfrojIGPIfI1LceReiHKIoMSkXeCIhc6xqubVqrQPvwcPMVnQbZqZACIj4qpf7pPXeVV9N+QMIfWNMESVm2wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Degree Of Completion",
            "title": "Degree Of Completion",
            "src": "/static/72ac52a3ecbcc3c2ed694a05474c4f4d/78d47/project-status-org_degree-of-completion.png",
            "srcSet": ["/static/72ac52a3ecbcc3c2ed694a05474c4f4d/9aebd/project-status-org_degree-of-completion.png 480w", "/static/72ac52a3ecbcc3c2ed694a05474c4f4d/78d47/project-status-org_degree-of-completion.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`One of the most important ratios in project management is the Degree of
Completion. On each status date, it should be requested by the project
controller from the responsible person for each DELIVERABLE. Together with the
specification of the effort already SPENT the `}<em parentName="p">{`Projection`}</em>{` can be calculated.
The Projection is the expected duration for each DELIVERABLE.`}</p>
      <ul>
        <li parentName="ul">{`On individual DELIVERABLES: PROJECTION = SPENT / DEGREE OF COMPLETION.`}</li>
        <li parentName="ul">{`On aggregated DELIVERABLES: DEGREE OF COMPLETION = Σ SPENTs|DOC>0 / Σ
PROJECTIONs.`}</li>
      </ul>
      <p>{`In other words: The Degree Of Completion is what has been spent divided by what
is projected. In order to not count ‘resultless’ amounts of time, SPENT is only
aggregated up if the Degree Of Completion % > 0. Amounts of time that have been
spent on tasks that have the status `}<em parentName="p">{`Ignored`}</em>{` are not considered in the
calculation of the degree of completion.`}</p>
      <h3 {...{
        "id": "traffic-lights",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#traffic-lights",
          "aria-label": "traffic lights permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Traffic lights`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.583333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAu0lEQVQI103B226DMAwAUP7/47ZKZRKrStcuwUkLweRiOxhaTdvTzmluPsVSnQPvvTCXUkQ4ZQ6LXMfRJy/C1n/VWuUfZiaixk/IsjoAY4yqitR925ZUlizg4HE3+6oMsMQ4z4gzhhAQMedca20ezlEhcL9UVyISkYgYJxy8hXjfhDNc+vOl6079uR8ASinMLCJNuGEt6zDY47ElIlXd9i2NmeMaDMxwfb6eWqfP7vT+dmjbD2us+bb65wcko99yEZGnkAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Status Evaluation",
            "title": "Status Evaluation",
            "src": "/static/d2448855e28a72256dc7c62452a1cd3d/78d47/project-status-org_status-evaluation.png",
            "srcSet": ["/static/d2448855e28a72256dc7c62452a1cd3d/9aebd/project-status-org_status-evaluation.png 480w", "/static/d2448855e28a72256dc7c62452a1cd3d/78d47/project-status-org_status-evaluation.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`Traffic lights form the center of any status report on a project. THE PROJECT
STATUS offers three different traffic lights:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`DL — Deadline: This traffic light is automatically set. If on the Current
Status Date a DELIVERABLE has a BY WHEN date that is passed and the Degree of
Completion is below 100% then the promised deadline has been missed. The
traffic light automatically turns red. Otherwise the traffic light turns
green.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`FC — Forecast: A status report serves the purpose to also give early warnings.
If a deadline is still in the future but it is foreseeable already now that it
will be missed this forecast information can already be given. Green
indicates, that the deadline will be met, amber indicates some uncertainty,
and red an impossibility to meet the deadline. Some projects prefer to use
orange instead of red for forecasts. The colorless traffic light symbol only
appears for those tasks where the deadline lies after the Current Status Date.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Q — Quality: Each DELIVERABLE should be quality assured by the recipient once
it was received. Green depicts acceptable quality, amber quality under review
and red unsatisfactory quality. The traffic light symbol only appears when the
degree of completion has been set to 100%.`}</p>
        </li>
      </ul>
      <p>{`The traffic light for the Deadline DL is set automatically. The statuses for the
`}<em parentName="p">{`Forecast FC`}</em>{` or the `}<em parentName="p">{`Quality Q`}</em>{` have to be set manually. Click on the traffic
light symbols to go forward through the traffic light circle. Shift click lets
you go backwards.`}</p>
      <p>{`In the case a deadline was missed or a quality was unsatisfactory a new
DELIVERABLE must be added with a new DEADLINE that remedies the previous
shortcomings.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAJklEQVQI12P49fD+r8cPfz158OvhvV8P7oK5D36/ePr/37//hAAAvfY5wGhFiXsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Comment Required",
            "title": "Comment Required",
            "src": "/static/79208e699d691d5539d85343076b70ef/78d47/project-status-org_comment-required.png",
            "srcSet": ["/static/79208e699d691d5539d85343076b70ef/9aebd/project-status-org_comment-required.png 480w", "/static/79208e699d691d5539d85343076b70ef/78d47/project-status-org_comment-required.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`In all cases a COMMENT should be given for all traffic light colors that are not
green.`}</p>
      <h3 {...{
        "id": "span-projection-slack-and-from",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#span-projection-slack-and-from",
          "aria-label": "span projection slack and from permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Span, Projection, Slack and From`}</h3>
      <p>{`When you switch on the Details you will find the following information.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "9.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWUlEQVQI1zXMOw6DQAwFQO5/0lDAwrIS9vvYUYr0o9kyo7v3z34cZ3fbJmC7XAxSxAvD93WdfyAoZvLllpkU5z3HGGVLWs9DsqoRudYiZFUiI+KXVkkSbPoLxqN0zeuUoGwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Projection · Slack · Span",
            "title": "Projection · Slack · Span",
            "src": "/static/a533b1a56fd096c92530c3a047e65b24/78d47/project-status-org_projection-slack-span.png",
            "srcSet": ["/static/a533b1a56fd096c92530c3a047e65b24/9aebd/project-status-org_projection-slack-span.png 480w", "/static/a533b1a56fd096c92530c3a047e65b24/78d47/project-status-org_projection-slack-span.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`In addition to the From, there are three duration numbers that are closely
linked to each other: Span, Projection, and Slack.`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`From:`}</strong>{` When does the work on a deliverable start.`}</li>
        <li parentName="ul"><strong parentName="li">{`Span:`}</strong>{` What is the duration between the from date and the by when date.
Even though undesired, a Span of 0 can happen and is possible. In this case
the Position and the Span are displayed in dark pink.`}</li>
        <li parentName="ul"><strong parentName="li">{`Proj:`}</strong>{` Proj means projection and linearly extrapolates the time already
spent on the deliverable.`}</li>
        <li parentName="ul"><strong parentName="li">{`Slack:`}</strong>{` Slack equals Span − Proj. Slack can be considered as some kind of
buffer, since the available span duration is larger the projected duration for
the deliverable completion. Obviously, negative Slack is possible, which means
that the Projection to complete the task is longer than the Span available. If
Slack is negative the Position and the Slack are displayed in dark pink.`}</li>
      </ul>
      <p>{`For aggregated rows the formula Span = Projection + Slack is not longer true.
The Span is still the time difference between the beginning and the end of the
task. However, Slack is not additive. Thus, it does not make sense to add it up
and, therefore, Slack will not be shown on an aggregated levels. Projection in
aggregated rows is a compromise: In order to see how much effort in form of
total projection needs to take place between these two time points the child
projections are added up for each aggregation row.`}</p>
      <p>{`THE PROJECT STATUS adheres to the
`}<a parentName="p" {...{
          "href": "/concepts/essence-project-management/"
        }}><em parentName="a">{`Golden Rule of Projekt Management`}</em></a>{` and
only focuses on output. For that reason it is only interested in DELIVERABLES
and their BY WHEN delivery dates. As a result it always assumes that work on a
DELIVERABLE is starting at its earliest possible date. The earliest possible
date is called FROM WHEN date. If a task has a given BY WHEN date but for some
reason cannot be started at its earliest possible FROM WHEN date the whole task
will simply have slack. When the Details switch is turned on, you can see the
FROM WHEN date behind the From. But for above reasons you cannot change it. If
you want to manage dependencies, have another look at the section Dependencies:
Precedents and Dependents.`}</p>
      <h3 {...{
        "id": "aggregation-and-inheritance",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#aggregation-and-inheritance",
          "aria-label": "aggregation and inheritance permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Aggregation and Inheritance`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "9.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAI0lEQVQI12P4////t7fff37++Z90wPDj54/3zz98/fCdDM0AS1p24nGgs7sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Pinned By When",
            "title": "Pinned By When",
            "src": "/static/3a26f32a9531302d627e5c470a07cf33/78d47/project-status-org_pinned-by-when-date.png",
            "srcSet": ["/static/3a26f32a9531302d627e5c470a07cf33/9aebd/project-status-org_pinned-by-when-date.png 480w", "/static/3a26f32a9531302d627e5c470a07cf33/78d47/project-status-org_pinned-by-when-date.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`One of the key features of THE PROJECT STATUS is its automatic aggregation and
inheritance.`}</p>
      <p>{`Aggregation is when information is aggregated from the children rows up into the
parents rows. Inheritance is when information is passed down from the parents
into the children rows, i.e. the children inherit this information.`}</p>
      <p>{`Aggregation is automatically carried out for the following fields: BY WHEN,
SPENT, % — Degree of Completion, DL — Deadline, FC — Forecast, Q — Quality. In
addition, aggregation is also carried out for Span and Projection.`}</p>
      <ul>
        <li parentName="ul">{`Traffic lights accumulate by passing up the worst traffic light color.`}</li>
        <li parentName="ul">{`Durations are added up.`}</li>
        <li parentName="ul">{`Aggregated Degree of Completion is calculated based on the underlying
information of Degree of Completion and SPENT per DELIVERABLE.`}</li>
        <li parentName="ul">{`If not pinned FROM WHEN dates and BY WHEN Dates result for each aggregated
DELIVERABLE from its underlying children.`}</li>
      </ul>
      <p>{`Inheritance is performed when BY WHEN dates are pinned. In this case no children
can be later than the pinned BY WHEN date. Also children inherit the Precedents
and Dependents from their parents.`}</p>
      <h3 {...{
        "id": "gantt-chart-and-milestones",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#gantt-chart-and-milestones",
          "aria-label": "gantt chart and milestones permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Gantt-Chart and Milestones`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVQoz4VR207CQBTsd/s/+gNGHwwk+gGggsRAKVDovd3e9n43dEkIQWWyD3OSMyc7M15VVTWopZTWWuNwJHaAMfbITtMVvAY0Qghj/lo4X/pF3PW9Y1rpr7m/DsMMVB8rP8nyIEzeF36WF7PF0t+GUZrnZUUJIZSdxIxIxwSRj3fT6ZOf+cX387ZMAcIEUSakRIQyzrkQXAillNL6JO57rAbDWtvxa7CcRwJTmDQME3sLXt0gJZUbiqqZfPr7uNoVTGpjjL4h7jrkorbWSm2DQ72LypfxJAoTggiTQv8TGIfInLtwxRgQx2AHDu9J5keogxQg0mICiVYXhzwExUUv7g07uGaj+81ynr49BLPRutgUislLzy2//per3RjbUi21CUu+imCctvtdUoMaQaQGeErom6k6W6jh1aEmBMMOYkwopT+8SnuO/X9/0QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gantt Chart",
            "title": "Gantt Chart",
            "src": "/static/43b32c79ce983faf683095fd32f158cf/78d47/project-status-org_gantt.png",
            "srcSet": ["/static/43b32c79ce983faf683095fd32f158cf/9aebd/project-status-org_gantt.png 480w", "/static/43b32c79ce983faf683095fd32f158cf/78d47/project-status-org_gantt.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`The Gantt-Chart is a graphical representation of the DELIVERABLES of a project.
A Gantt-Chart is comprised of Gantt-Bars and each Gantt-Bar is comprised of
Gantt-Boxes. The initials of the owner (WHO) of the deliverable is shown before
the Gantt-Bar, the deliverable thereafter.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAFklEQVQI12P4+u3b799//v79+590AAAVZDuY2NkZgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gantt View",
            "title": "Gantt View",
            "src": "/static/721dd573cb191ffccce216bfa64cf3bd/78d47/project-status-org_gantt-view.png",
            "srcSet": ["/static/721dd573cb191ffccce216bfa64cf3bd/9aebd/project-status-org_gantt-view.png 480w", "/static/721dd573cb191ffccce216bfa64cf3bd/78d47/project-status-org_gantt-view.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`For greater clarity you can increase or decrease the size of the Gantt-Chart by
clicking on the ’+’ and ’−’ buttons at the top right of the Gantt-Chart. To the
left of the ’+’ and ’−’ buttons you see a character ‘d’ (= day) or ‘w’ (= week).
With these you can toggle between the day view and the week view of the Gantt
Chart.`}</p>
      <p>{`Since nobody ever knows week numbers, the time line in the week view shows the
first date of the business week. Obviously weekends are removed.`}</p>
      <p>{`Status dates or weeks in which status date occur are shown in blue. The Current
Status Date or Week is show in dark pink.`}</p>
      <p>{`In the day view, each full Gantt-Box represents a full day. In the week view,
each full Gantt-Box represents a full week.`}</p>
      <p>{`A black line at the end of a Gantt-Bar indicates a milestone. The Milestone can
be set by pinning a BY WHEN date of a DELIVERABLE. In the day view the milestone
is always at the end of the business day even though your task may end during
the day.`}</p>
      <p>{`By clicking on the caret icon `}<BiCaretRight style={{
          display: "inline-block",
          position: "relative",
          top: "-2px"
        }} mdxType="BiCaretRight" />{` left
to the Position you can fold and unfold aggregated deliverables. If you click on
the Position it will become highlighted.`}</p>
      <p>{`In the Gantt chart if a bar represents a DELIVERABLE with work that has slack,
it will show two colors: the Slack duration has a slightly lighter color then
the color for the Projection duration.`}</p>
      <h3 {...{
        "id": "senior-management-requests-issues-and-ratios",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#senior-management-requests-issues-and-ratios",
          "aria-label": "senior management requests issues and ratios permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Senior Management Requests, Issues and Ratios`}</h3>
      <p>{`Usually, larger projects are embedded in an organization. As larger projects
have a more significant impact in the organization they typically bring many
stakeholders. Also, the project result may lead to significant changes in the
organization itself. The outside area of a project is called the
`}<a parentName="p" {...{
          "href": "https://project-status.org/concepts/project-context",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Project Context`}</a>{`. Most
often the project manager cannot manage issues outside the project and for this
needs the support of Top Management that is hierarchical above the project and
the project manager. In these cases, Top Management has to perform certain
activities.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.583333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVQoz5WRbY+DIBCE+///4SUVrYrCgi5U3qQIF8/LpW1Mk5vMx3l2MtmLj8Wuxf3Yr7n8R5dZmVlZiQa1sz65sNu/Oj4e53DVsK8r7UYlVTCh3H1ZfNG2aFe0zdpmXBLn8hymlA495SOTIDhnnDE2jloprVTO+4qUEgCcw03ddG1PrqSpGkLqtm2X+5Jz3rbf/Z9gAQIAhBDWWOfc0Zaf9Alubx2pCCE17QfG+LwLEXGSEyIqpZ1zAHAcfYc545OcAMRAx67tjTFviW3b5nk+b67rpia7hZCIKKWM8eUxOecQwjnsvV/D+rf2SG9PSinFGE/hb9EiREr00ntFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Requests",
            "title": "Requests",
            "src": "/static/39832a8d9039fa10a36198c60aec1dde/78d47/project-status-org_requests.png",
            "srcSet": ["/static/39832a8d9039fa10a36198c60aec1dde/9aebd/project-status-org_requests.png 480w", "/static/39832a8d9039fa10a36198c60aec1dde/78d47/project-status-org_requests.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`There are a number of pre-defined and typical requests to senior management from
which you can choose on the Setup page. Your own issues can also be added. The
selected requests will then show on the Summary Sheet.`}</p>
      <p>{`The Project Manager has the objective that the project is delivered on time, in
scope and quality and within budget. He does so by organizing the deliverables
and by making sure that there are owners and receivers for these deliverables.
But good project management also has keep an eye on the global level. Are
general issues arising in and around the project. These can be change issues in
the organization, scope and quality issues of overall nature, risk issues from
the context, or knowledge issues during and at the end of the project.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAX0lEQVQI1wXBARLCMAgEwP7/nzqOGvA4ICka6u7hENJF6WTvPr8FIDMNVlXX3itTzWE25+zetE9EkEbyCL0r8vZ0qPZuTAwZdZYOWWd1RqrcZb3eCI/r+hGPjOXuMvQPU6lybAjslwwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Issues",
            "title": "Issues",
            "src": "/static/f96de2135c9e6df4565cd582ad8da407/78d47/project-status-org_issues.png",
            "srcSet": ["/static/f96de2135c9e6df4565cd582ad8da407/9aebd/project-status-org_issues.png 480w", "/static/f96de2135c9e6df4565cd582ad8da407/78d47/project-status-org_issues.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`For that reason THE PROJECT STATUS also provides a section on issues. On the
project Setup page you can evaluate the severity of such issues and set a
checkmark if some measures have already been taken. The information give here is
also mirrored on the Status Sheet page.`}</p>
      <p>{`Remember, project success is defined to be a project delivery of the final
product:`}</p>
      <ul>
        <li parentName="ul">{`in scope and quality`}</li>
        <li parentName="ul">{`in budget`}</li>
        <li parentName="ul">{`in time`}</li>
        <li parentName="ul">{`with a satisfied customers, and ideally`}</li>
        <li parentName="ul">{`with a satisfied team, that is proud on its achievement.`}</li>
      </ul>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "8.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAUElEQVQI1xXJQRLAMAgDsf7/uw0JEOzt9Co9tkeso+qxifK7r+TTrHT1jIh0pGQDse+JA+zyI/1U7aoB7rBzJPVw2tn338uO0DSwIt/1Atl8DqN1rhTKVUoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "All Ratios",
            "title": "All Ratios",
            "src": "/static/aecdb402d6c553bef88191d255a4c7ef/78d47/project-status-org_ratios-all.png",
            "srcSet": ["/static/aecdb402d6c553bef88191d255a4c7ef/9aebd/project-status-org_ratios-all.png 480w", "/static/aecdb402d6c553bef88191d255a4c7ef/78d47/project-status-org_ratios-all.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p>{`In order to achieve these critical success criteria THE PROJECT PLANNER employs
ratios, that directly relate to the definition of project success:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Expenses Budget T€`}</strong>{`: the total amount of expenses that has been assigned to
the project.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Expenses Actual T€`}</strong>{`: the amount of expenses that has been expended from the
Start Date of the Project to the Current Status Date.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Expenses Projection T€`}</strong>{`: the amount of expenses that will like be expended
in the project. It is calculated as Expenses Projection = Expenses Actual /
Degree of Completion.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Time Budget d`}</strong>{`: the amount of time in business days between the End Date
and the Start Date of the project plus one day. Plus one day, because the
start day also counts.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Time Actual d`}</strong>{`: the amount of time in business days between the Current
Status Date and the Start Date of the project plus one day. If the Current
Status Date is after the project End Date is set to the Time Budget.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Time Projection d`}</strong>{`: the amount of time in business days that will like be
expended in the project. It is calculated as Time Projection = Time Actual /
Degree of Completion.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`DOC:`}</strong>{` Degree of Completion. This number tells how much the project is
already completed overall. It is based on the DOC numbers per DELIVERABLE and
accumulated up.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`DOXS`}</strong>{`: Degree of Expenses Spent. This number tells how much of the Expenses
Budget has already been expended. The underlying budget numbers need to be
entered in the project Setup page.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`DOTS`}</strong>{`: Degree of Time Spent. This number tells how much of the available
Time Budget has already been used up.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`DOC/DOXS:`}</strong>{` Degree of Completion / Degree of Expenses Spent. This number
shows if the project is already completed more than the Expenses Budget is
spent. Ideally, the number should be above 100%. A traffic light color depicts
the criticality of this number.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`DOC/DOTS:`}</strong>{` Degree of Completion / Degree of Time Spent. This number shows
if the project is already completed more than the available time is used up.
Ideally, the number should be above 100%. A traffic light color depicts the
criticality of this number.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Customer Satisfaction:`}</strong>{` Whether or not a project is run in a classical or
an agile manner, the (external / internal) customer satisfaction is key. In
the end, one way or the other the customer is paying for the project.
Therefore, the customer needs to be kept in the picture. This ratio is a
reminder for that. The actual number needs to be provided from outside the THE
PROJECT STATUS and can be entered on the Setup page.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Team Satisfaction:`}</strong>{` A high team satisfaction is an excellent (early
warning) indicator as to whether the project is going to be a success. For
that reason it should be taken seriously. The actual number needs to be
provided from outside the THE PROJECT STATUS and can be entered on the Setup
page.`}</p>
        </li>
      </ul>
      <h2 {...{
        "id": "progress",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#progress",
          "aria-label": "progress permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Progress`}</h2>
      <p>{`THE PROJECT STATUS offers a tab that helps to visually analyze the progress of a
project by help of charts. The charts show important KPIs (key performance
indicators) of the project over time at status days.`}</p>
      <p>{`On each chart, the first date shown is the project start date, the last date
shown is the project end date. The first and the last day of a project may not
be status dates, so there might not be information available for these dates. On
the line graphs status dates are depicted with a circle, so you can easily see
if the start or end date of a project are also status dates.`}</p>
      <p>{`The lines values between status dates are linearly interpolated. For best
visualization, the status dates are equally distributed on the x-axis even
though they may have different distances to each other in terms of business
days. For that reason, the “straight” budget lines may likely show some kinks.`}</p>
      <p>{`There are six charts:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Degree of Completion % (Time & Actual):`}</strong>{` The Time is linearized from the
beginning to the end of the project and shown in blue. The actual values are
in dark pink. If the dark pink line is over (under) the blue line the project
is ahead of (behind) time in terms of completion.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Expenses T€ (Budget & Actual):`}</strong>{` The expenses budget is linearized over time
and shown in blue. The Actual values are in dark pink. If the dark pink line
is over (under) the blue line the project is more expensive (cheaper) then
budgeted.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Non-Aggregated Deliverables Π (DONE, WIP & NYS):`}</strong>{` DONE deliverables are in
dark blue, WIP (Work In Progress) deliverables in medium blue, and NYS (Not
Yet Started) in light blue. Aggregated, unresolved or ignored deliverables are
not considered and, therefore, not counted.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Poor Quality Deliverables & Unresolved Issues Π:`}</strong>{` Left bar — Poor Quality
Deliverables in pieces (Π) are deliverables that are 100% completed but that
show a quality of red, orange or yellow. Aggregated, unresolved or ignored
deliverables are not considered and, therefore, not counted. Right Bar —
Unresolved Issues in pieces (Π) are all deliverables marked as unresolved.
Their bars are displayed in colors as defined on the Setup page.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Number Π of persons involved in the project:`}</strong>{` Persons counted to the core
of the project are assigned to at least one deliverable as WHO or TO WHOM. All
other persons are considered to belong to the context of the project.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Satisfaction:`}</strong>{` Left bar — Customer Satisfaction in percent. Right bar —
Team Satisfaction in percent. Both bars are displayed in colors as defined in
the Setup. The maximum is 100%.`}</p>
        </li>
      </ul>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      